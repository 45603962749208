
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { utcZonesOptions } from '@/definitions/_general/_data/utc'

export default defineComponent({
  name: 'ScheduleSurveyModal',
  components: { TmFormLine, TmButton, TmModal },
  setup() {
    const sendDate = ref(new Date('2022-04-19'))
    const sendHours = ref('13')
    const sendMinutes = ref('00')
    const timezone = ref(utcZonesOptions[16])

    return {
      sendDate,
      sendHours,
      sendMinutes,
      timezone,
    }
  },
})
